@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@300;400;500;600;700;800&display=swap');
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'Mukta', sans-serif;;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.custom-td{
  min-width: 200px;
}

.custom-input{
  width: calc(100% - (32px + 32px + 32px + 32px + 64px));
  background-color: transparent;
  /* flex-grow: 1; */
  border: none;
  outline: none;
  font-size: 16px;
  /* color: #34b6e563; */
}


.h-100{
  height: 100%;
  transition: all 0.2s ease-in-out;
  position: relative;
  /* color: rgb(4, 61, 4); */
}

.w-100{
  width: 100%;
}

/* .mantine-Drawer-drawer{ */
  /* width: 90% !important; */
  /* height: 70vh !important;
  border-radius: 20px 20px 0 0 !important;
  margin: 0 auto;
} */